body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.vertical-center {
  min-height: 70%; /* Fallback for browsers do NOT support vh unit */
  min-height: 70vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.largeicon {
  font-size: 150px !important;
}

.dialogicon {
  font-size: 80px !important;
}

.smallfont {
  font-size: 8px !important;
}

.mediumfont {
  font-size: 16px !important;
}

.memberkardred {
  color: #832029;
}

.memberkarddottedborder {
  border: 1px dashed #a0a0a0 !important;
  border-radius: 8px;
}

.memberkardreddottedborder {
  border: 1px dashed #dc3545 !important;
  border-radius: 8px;
}

.greyborder {
  border: 1px solid #c0c0c0 !important;
}

.memberkardgreen {
  color: #0e5132;
}

.menuicon {
  color: #303030;
  border: 1px dashed #dc3545;
  border-radius: 8px;
  transition: background-color 0.25s ease;
}

#redicon {
  color: #dc3545;
}

.menuicon:hover {
  cursor: pointer;
  background-color: #dc3545;
  color: #fff !important;
  border: 1px solid #dc3545;
}

.menuicon:hover #redicon {
  color: #fff !important;
}

.carddesign {
  background: linear-gradient(-45deg, #ff7575, #ed3030, #f66868);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  border-radius: 12px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}